import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

export default function pgmBox(props) {

  const [width, setWidth] = useState(723);
  const [height, setHeight] = useState(0);
  const [fontColor, setFontColor] = useState('black');

  useEffect(() => {
    if (props.data.text_color == 'w') {
      setFontColor('white');
    } else {
      setFontColor('black');
    }
  }, [])

  // 모듈러 링크 리액트 hook
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a,b) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    let contentId;
    let contentTitle;
    if(b  === 'pgm'){
      contentId = '프로그램';
      contentTitle = props.data.title;
    }else if(b  === 'mnu'){
      contentId = '메뉴';
      contentTitle = props.data.button_nm;
    }

    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '프로그램형', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: props.currentSlide, //모듈 내에서 콘텐츠의 위치
        programId: props.data.pgm_id, //프로그램의 아이디 (추가)
        programTitle: props.data.title, //프로그램의 제목 (추가)
        contentId: contentId,//식별자
        contentTitle: contentTitle, //콘텐츠의 제목  (Require)
        contentType: 'PR', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };


  const handleImageLoad = (e) => {
    const nHeight = e.naturalHeight;
    const nWidth = e.naturalWidth;
    const scale = e.naturalWidth / 723;
    const scaleHeight = nHeight / scale;

    setHeight(scaleHeight);
    if (props.forceUpdate) {
      props.forceUpdate();
    }
  };

  const handleOpenPopup = () => {
    //  485 * 928
    //통계모듈
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '프로그램형', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: props.currentSlide, //모듈 내에서 콘텐츠의 위치
        programId: props.data.pgm_id, //프로그램의 아이디 (추가)
        programTitle: props.data.title, //프로그램의 제목 (추가)
        contentId: props.data.talk_id,//식별자
        contentTitle: '플레이톡', //콘텐츠의 제목  (Require)
        contentType: 'LK', //콘텐츠의 타입 (별도 표 참조)
      }
    });
    
    const popup = window.open('https://www.sbs.co.kr/playtalk/module.html?code=' + props.data.talk_id, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
  }

  return (

      <div className="swiper-slide slide-slide">
        <div className="swiper-cont">
          {/* <!-- 프로그램형_폰트_Black --> */}
          <div className="spotModuleWrap_ProgramBlack" style={{ backgroundColor: props.data.bg_color }}>
            {/* <!-- 편성 컬러 -->          */}
            <div className="spotProgramContent_w" style={{ backgroundColor: props.data.bg_color }}>
              <div className="spotProgramCont">
                {props.data.button_view_yn === 'N' ?
                    <>
                      <div className="spotProgramLink">
                        <strong className="spotProgram-Title" style={{ color: fontColor }}>{props.data.title}</strong>
                        <span className="spotProgram-Text" style={{ color: fontColor }}>{props.data.sub_title}</span>
                        <span className="spotProgram-Info" style={{ color: fontColor }}>{props.data.pgm_time}</span>
                      </div>
                    </>
                    :
                    <>
                      <a href={props.data.link_url} >

                          <a
                              onClick={(e) => handleClick(e, 'pgm')}
                              target={props.data.link_new_yn === 'Y' ? '_blank' : ''}
                              className="spotProgramLink"
                          >

                          <strong className="spotProgram-Title" style={{ color: fontColor }}>{props.data.title}</strong>
                          <span className="spotProgram-Text" style={{ color: fontColor }}>{props.data.sub_title}</span>
                          <span className="spotProgram-Info" style={{ color: fontColor }}>{props.data.pgm_time}</span>
                        </a>
                      </a>
                    </>
                }
                <div className="spotProgramBtn_w">

                    <a  href={props.data.link_url} onClick={(e) => handleClick(e, 'mnu')}  target={props.data.link_new_yn == 'Y' ? '_blank' : ''} className="btnType-roundWhite" style={{ visibility: props.data.button_view_yn == 'Y' ? 'visible' : 'hidden' }}>
                      <strong className="btnType-title">{props.data.button_nm}</strong>
                    </a>




                  {/*<a onClick={handleOpenPopup} href="#none" className="btnType-playtalkNormal" style={{ visibility: (props.data.talk_view_yn == 'Y' ) ? 'visible' : 'hidden' }}>*/}
                  {/*  <span className="iconSvg_playtalk"></span>*/}
                  {/*  <strong className="btnType-title">플레이톡</strong>*/}
                  {/*</a>*/}

                  {
                      props.data.talk_view_yn === 'Y' && (
                          <a onClick={handleOpenPopup} href="#none" className="btnType-playtalkNormal">
                            <span className="iconSvg_playtalk"></span>
                            <strong className="btnType-title">플레이톡</strong>
                          </a>
                      )
                  }


                </div>
              </div>
            </div>

            {
              (props.data.pgm_id === null) && props.data.button_view_yn === 'N'
                  ? (
                      <>
                        <Image
                            src={props.data.pc_img_url}
                            className="spotModuleImg"
                            layout='fixed'
                            width={724}
                            height={450}
                            quality={props.data.imageQuality || 75}
                            alt={props.data.pc_img_attr}
                        />
                      </>
                  )
                  :
                  (
                      <>
                        <a href={props.data.pgm_id !== null ? props.data.home_url : (props.data.pgm_id === null || props.data.button_view_yn === 'Y') ? props.data.link_url : ''} >
                          <a  onClick={(e) => handleClick(e, 'pgm')} className="spotModuleImage_w">
                            <Image
                                src={props.data.pc_img_url}
                                className="spotModuleImg"
                                layout='fixed'
                                width={724}
                                height={450}
                                quality={props.data.imageQuality || 75}
                                alt={props.data.pc_img_attr}
                            />
                          </a>
                        </a>
                      </>
                  )
            }

          </div>
        </div>
      </div>

  );

}