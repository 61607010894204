import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from "react";
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';


export default function contentsBox(props) {

  // 모듈러 링크 리액트 hook
  useEffect(() => {
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    let contentId;
    let contentTitle;
    let contentType;
    if (c === 'vod') {
      contentId = b.mda_id
      contentTitle = b.title;
      contentType = 'V';
    } else if (c === 'clip') {
      contentId = b.mda_id
      contentTitle = b.title;
      contentType = 'C';
    } else {
      contentId = '';
      contentTitle = b.title;
      contentType = 'PH';
    }
    //alert('contentTitle== ' + contentTitle);

    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '콘텐츠형', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: props.currentSlide, //모듈 내에서 콘텐츠의 위치
        programId: b.pgm_id, //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 제목 (추가)
        contentId: contentId,//식별자
        contentTitle: contentTitle, //콘텐츠의 제목  (Require)
        contentType: contentType, //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };


  return (
    <div className="swiper-slide slide-slide">
      <div className="swiper-cont">
        <div className="spotModuleGroup">
          {
            props.data.contents.map((item, index) => {
              if (item.type == 'photo') {
                return (
                  <div key={'mtc_' + index} className={`spotModuleWrap_ContentPhoto ${item.title == '' || item.title == null ? 'type_noText' : ''}`}>
                    {
                      item.link_url == null || item.link_url == ''
                        ? (
                          <>
                            <div className="spotModuleLink">
                              <span className="spotModuleImage_w">
                                <Image src={item.pc_img_url} className="spotModuleImg" layout='fixed' width={588} height={450} quality={item.imageQuality || 100} alt={item.pc_img_attr} />
                                <span className="iconModuleInfo-iconPlay"><span className="iconSvg_play"><i className="hide">재생</i></span></span>
                              </span>
                              <div className="spotProgramCont">
                                <strong className="spotProgram-Title">{item.title}</strong>
                                {/* <span className="spotProgram-ProgName">{item.type}</span> */}
                              </div>
                            </div>
                          </>
                        )
                        :
                        (
                          <>
                            <a href={item.link_url} >
                              <a onClick={(e) => handleClick(e, item, 'photo')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                                <span className="spotModuleImage_w">
                                  <Image src={item.pc_img_url} className="spotModuleImg" layout='fixed' width={588} height={450} quality={item.imageQuality || 100} alt={item.pc_img_attr} />
                                  <span className="iconModuleInfo-iconPlay"><span className="iconSvg_play"><i className="hide">재생</i></span></span>
                                </span>
                                <div className="spotProgramCont">
                                  <strong className="spotProgram-Title">{item.title}</strong>
                                  {/* <span className="spotProgram-ProgName">{item.type}</span> */}
                                </div>
                              </a>
                            </a>
                          </>
                        )
                    }
                  </div>

                );
              } else if (item.type == 'clip') {
                return (
                  <div key={'mtc_' + index} className="spotModuleWrap_ContentClip">
                    {
                      item.link_url == null || item.link_url == ''
                        ? (
                          <>
                            <div className="spotModuleLink">
                              <span className="spotModuleImage_w">
                                {/* <Image src={item.pc_img_url} className="spotModuleImg" layout='fixed' width={723} height={450} quality={item.pc_img_attr || 75} alt={item.pc_img_attr} /> */}
                                <img src={item.pc_img_url} className="spotModuleImg" alt={item.pc_img_attr} />
                                <span className="iconModuleInfo-cliptime"><span className="iconModule-text">{item.plytm}</span></span>
                              </span>
                              <div className="spotProgramCont">
                                <strong className="spotProgram-Title">{item.title}</strong>
                              </div>
                            </div>
                          </>
                        )
                        :
                        (
                          <>
                            <a href={item.link_url} >
                              <a onClick={(e) => handleClick(e, item, 'clip')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                                <span className="spotModuleImage_w">
                                  {/* <Image src={item.pc_img_url} className="spotModuleImg" layout='fixed' width={723} height={450} quality={item.pc_img_attr || 75} alt={item.pc_img_attr} /> */}
                                  <img src={item.pc_img_url} className="spotModuleImg" alt={item.pc_img_attr} />
                                  <span className="iconModuleInfo-cliptime"><span className="iconModule-text">{item.plytm}</span></span>
                                </span>
                                <div className="spotProgramCont">
                                  <strong className="spotProgram-Title">{item.title}</strong>
                                </div>
                              </a>
                            </a>
                          </>
                        )
                    }
                  </div>
                );
              } else if (item.type == 'vod') {
                return (
                  <div key={'mtc_' + index} className="spotModuleWrap_ContentVod">
                    {
                      item.link_url == null || item.link_url == ''
                        ? (
                          <>
                            <div className="spotModuleLink">
                              <span className="spotModuleImage_w">
                                <img src={item.pc_img_url} className="spotModuleImg" alt={item.pc_img_attr} />
                                <span className="iconModuleInfo-iconPlay"><span className="iconSvg_play"><i className="hide">재생</i></span></span>
                              </span>
                              <div className="spotProgramCont">
                                <strong className="spotProgram-Title">{item.title}</strong>
                                <span className="spotProgram-ProgName">{item.srs_no} 회 {item.pgm_nm}</span>
                              </div>
                            </div>

                          </>
                        )
                        :
                        (
                          <>
                            <a href={item.link_url} >
                              <a onClick={(e) => handleClick(e, item, 'vod')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                                <span className="spotModuleImage_w">
                                  <img src={item.pc_img_url} className="spotModuleImg" alt={item.pc_img_attr} />
                                  <span className="iconModuleInfo-iconPlay"><span className="iconSvg_play"><i className="hide">재생</i></span></span>
                                </span>
                                <div className="spotProgramCont">
                                  <strong className="spotProgram-Title">{item.title}</strong>
                                  <span className="spotProgram-ProgName">{item.srs_no}회 {item.pgm_nm}</span>
                                </div>
                              </a>
                            </a>
                          </>
                        )
                    }
                  </div>
                );
              }
            })

          }

        </div>
      </div>
    </div>
  );

}