
export const SUPPORT_CHANNELS = ['S01'];
export const CODE_KOR = 'korean';
export const CODE_ENG = 'english';
export const CODE_JP = 'japanese';
export const CODE_CN = 'chinese';
export const CODE_ESP = 'spanish';
export const CODE_PT = 'portuguese';
export const TEXT_KOR = '한국어';
export const TEXT_ENG = 'English';
export const TEXT_JP = '日本語';
export const TEXT_CN = '中文';
export const TEXT_ESP = 'español';
export const TEXT_PT = 'português';