import React, { useEffect, useRef, useState } from 'react';
import ReactSlider from 'react-slick';

export default function playBannerBox(props) {

  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    const link = document.querySelector('.playMovieBnr_link');
    const closed = document.querySelector('.playMovieBnr_closed');
    let layer = document.querySelector('.playPromotion_inner');

    link.addEventListener('click', (e) => {
      e.preventDefault();
      layer.classList.add('playMovie-Open');
      setShowPlayer(true);
    });

    closed.addEventListener('click', (e) => {
      e.preventDefault();
      layer.classList.remove('playMovie-Open');
      setShowPlayer(false);
    });
  });

  try {
    return (
      <>
        {/* {/*<!-- 화이트 배경 -->*/}
        <div className="mainContents-typeWhite" >

          {/*<!-- SBS PLAY 브랜드 -->*/}
          <div className="contentsBlock_w">
            <div className="moduleTitleline_w hide">
              <h2 className="moduleTitleline-Title">SBS PLAY 브랜드</h2>
            </div>
            <div className="playPromotion_w">
              <div className="playPromotion_inner">{/*<!-- playMovie-Open 클래스 추가시 영상 영역 노출 -->*/}
                <a href="/playzone" className="playZone_link">
                  <img src="/images/playBnr.gif" alt="즐거움을 잇다! SBS Play 플레이존에서 즐거움을 이어가세요! 플레이존 바로가기" />
                </a>
                <div className="playMovie_w">
                  <a href="#none" className="playMovieBnr_link">
                    <span className="icon_play">
                      <span className="iconSvg_play"><i className="hide">VOD</i></span>
                    </span>
                    <img src="/images/movieBnr.png" alt="SBS Play" className="MovieBnr_img" />
                    <strong className="playMovieBnr_open">
                      <span className="iconSvg_play"><i className="hide">VOD</i></span>
                      <em className="playMovieBnr_text">영상보기</em>
                    </strong>
                  </a>
                </div>
                <div className="playMovieLayer_w">
                  <div className="playMovieLayer_inner">
                    <div className="playMovieLayer_video">
                      {showPlayer && <sbs-player media="22000524316" w="100%" h="auto" noad></sbs-player>}
                    </div>
                    <a href="#none" className="playMovieBnr_closed">
                      <em className="playMovieBnr_text">영상닫기</em>
                      <span className="iconSvg_closed"><i className="hide">닫기</i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    return (<></>);
  }

}