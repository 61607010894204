import * as TRANSLATE from '../../common/constants/live/translate';
/**
 * For Web Accessibility
 *
 * 두 시작/종료시간을 받아 얼마나 재생했는지 표시
 * @function
 * @param {String} 12:00, 13:00 or 1200, 1300
 * @returns {boolean} 포함여부
 */
export const toPercentTimeDesc = (starttime, endtime, percent) => {
  const hourStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[0] : starttime.substring(0, 2);
  const minStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[1] : starttime.substring(2, 4);
  const hourEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[0] : endtime.substring(0, 2);
  const minEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[1] : endtime.substring(2, 4);

  const hourStartTime = parseInt(hourStartSeparator);
  const hourEndTime = parseInt(hourEndSeparator);
  const minStartTime = (hourStartTime * 60) + parseInt(minStartSeparator);
  const minEndTime = (hourEndTime * 60) + parseInt(minEndSeparator);

  const duration = parseInt(minEndTime - minStartTime);
  const result = `${percent}%, 총 영상 길이 ${duration}분 가운데 현재 ${parseInt((minEndTime - minStartTime) * (percent / 100))}분 재생 중..`;
  return result;
};
/**
 *
 * url query params update
 * @function
 * @param {String, String, String}
 * @returns {String} 포함여부
 */
export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
};

/**
 * 기존 온에어 함수 그대로 가져옴
 * 두 시작/종료시간을 받아 현재시간이 포함되는지 반환
 * @function
 * @param {String} 12:00, 13:00 or 1200, 1300
 * @returns {boolean} 포함여부
 */
export const isNowIncludeTimes = (starttime, endtime) => {
  const hourStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[0] : starttime.substring(0, 2);
  const minStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[1] : starttime.substring(2, 4);
  const hourEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[0] : endtime.substring(0, 2);
  const minEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[1] : endtime.substring(2, 4);

  const hourStartTime = parseInt(hourStartSeparator);
  let hourEndTime = parseInt(hourEndSeparator);

  const now = new Date();
  const tzOffset = 9; // seoul..
  const tz = now.getTime() + (now.getTimezoneOffset() * 60000) + (tzOffset * 3600000);
  now.setTime(tz);

  let minuteCurrent;
  if (hourEndTime < hourStartTime) {
    hourEndTime += 24;
    minuteCurrent = (now.getHours() + 24) * 60 + now.getMinutes();
  } else {
    minuteCurrent = now.getHours() * 60 + now.getMinutes();
  }

  const minuteStartTime = (hourStartTime * 60) + parseInt(minStartSeparator);
  const minuteEndTime = (hourEndTime * 60) + parseInt(minEndSeparator);

  if (minuteCurrent >= minuteStartTime && minuteCurrent < minuteEndTime) {
    return true;
  } else {
    return false;
  }
};
/**
 * 두 시작/종료시간을 받아 퍼센트로 반환
 * @function
 * @param {String} 12:00, 13:00 or 1200, 1300
 * @returns {number} 100분율 퍼센트
 */
export const toPercentTime = (starttime, endtime) => {
  try {
    const hourStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[0] : starttime.substring(0, 2);
    const minStartSeparator = starttime.indexOf(':') > -1 ? starttime.split(':')[1] : starttime.substring(2, 4);
    const hourEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[0] : endtime.substring(0, 2);
    const minEndSeparator = endtime.indexOf(':') > -1 ? endtime.split(':')[1] : endtime.substring(2, 4);

    const hourStartTime = parseInt(hourStartSeparator);
    let hourEndTime = parseInt(hourEndSeparator);

    const now = new Date();
    const tzOffset = 9; // seoul..
    const tz = now.getTime() + (now.getTimezoneOffset() * 60000) + (tzOffset * 3600000);
    now.setTime(tz);

    let minuteCurrent;
    if (hourEndTime < hourStartTime) {
      hourEndTime += 24;
      minuteCurrent = (now.getHours() + 24) * 60 + now.getMinutes();
    } else {
      minuteCurrent = now.getHours() * 60 + now.getMinutes();
    }

    const minuteStartTime = (hourStartTime * 60) + parseInt(minStartSeparator);
    const minuteEndTime = (hourEndTime * 60) + parseInt(minEndSeparator);

    return parseInt((1 - (minuteEndTime - minuteCurrent) / (minuteEndTime - minuteStartTime)) * 100);
  } catch (e) {
    return -1;
  }
};

/**
 * 날짜객체를 특정 포맷으로 변경하여 문자열로 넘겨준다.
 * @function
 * @param date
 * @param format
 * @returns {String}
 */
export const toFormattedDate = (date, format) => {
  const weekNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

  return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p|y\/t)/gi, function ($1) {
    switch ($1) {
      case 'yyyy': return date.getFullYear();
      case 'yy': return (date.getFullYear() % 1000);
      case 'MM': return (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
      case 'dd': return date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
      case 'E': return weekNames[date.getDay()];
      case 'HH': return date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
      case 'hh': return ((date.getHours() % 12) ? date.getHours() % 12 : 12);
      case 'mm': return date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
      case 'ss': return date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds();
      case 'a/p': return date.getHours() < 12 ? '오전' : '오후';
      case 'y/t': return date.getDate() === new Date().getDate() ? '오늘' : '어제';
      default: return $1;
    }
  });
};

/**
 * 초를 ISO8601 포맷으로 변경한다.
 * @param seconds
 * @returns {string}
 */
export const toIso8601 = (seconds) => {
  const secondsForMinute = 60;
  const minute = seconds >= secondsForMinute ? Math.floor(seconds / secondsForMinute) : 0;
  const second = seconds - (minute * secondsForMinute);

  return 'PT' + minute + 'M' + second + 'S';
};

/**
 * 초를 HH:MM 포맷으로 변경한다.
 * @param seconds
 * @returns {string}
 */
export const toTimeStamp = (seconds = 0) => {
  if (seconds > 0) {
    const secondsForMinute = 60;
    let minute = seconds >= secondsForMinute ? Math.floor(seconds / secondsForMinute) : 0;
    minute = (minute < 10) ? '0' + minute : minute;
    let second = seconds - (minute * secondsForMinute);
    second = (second < 10) ? '0' + second : second;

    return minute + ':' + second;
  } else {
    return '';
  }
};

/**
 * 초를 HH:MM 포맷으로 변경한다.
 * @param seconds
 * @returns {string}
 */
export const toTimeStampString = (date) => {
  const sysdate = new Date(date);
  let hh = sysdate.getHours();

  hh = (hh < 10) ? ('0' + hh) : hh;
  const mm = sysdate.getMinutes() < 10 ? ('0' + sysdate.getMinutes()) : sysdate.getMinutes();

  if (hh > 12) {
    hh -= 12;
    hh = '오후 ' + hh;
  } else if (hh === 12) {
    hh = '오후 ' + hh;
  } else {
    hh = '오전 ' + hh;
  }

  const result = hh + ':' + mm;

  return result;
};

/**
 * 숫자에 1,000 단위로 콤마를 넣어 변환한다.
 * @param num
 * @returns {string}
 */
export const toComma = (num) => {
  num = num + '';
  const len = num.length;
  let point = num.length % 3;
  let str = num.substring(0, point);
  while (point < len) {
    if (str !== '') str += ',';
    str += num.substring(point, point + 3);
    point += 3;
  }

  return str;
};

export const to2Digit = (number) => {
  const NN = (number < 10) ? '0' + number : number;
  return NN;
};

export const toHTTPS = (strUrl) => {
  const httpsProtocol = (strUrl.indexOf('http://') > -1) ? strUrl.replace('http://', 'https://') : strUrl;
  return httpsProtocol;
};

export const deduplArray = (array, id, key) => {
  return Object.values(
    array.reduce((acc, el) => {
      if (!acc[el[id]] || acc[el[id]][key] < el[key]) {
        acc[el[id]] = el;
      }
      return acc;
    }, {})
  );
};
// * main 실시간 라이브 영역 제작발표회 채널 노출을 위해 예외처리, 라디오 채널만 데이터 종복제거 실시
export const deduplChannelArray = (array, id, key) => {
  // id = 'channelname';
  // key = 'channelid';
  return Object.values(
    array.reduce((acc, el) => {
      if (!acc[el[id]] || acc[el[id]][key] < el[key] || el.channelid.indexOf('EVENT') > -1) {
        if (el.channelid.indexOf('EVENT') > -1) { el[id] = el[id] + " " + el[key] }
        acc[el[id]] = el;
      }
      return acc;
    }, {})
  );
};

/**
 * 온에어 자막 번역 코드-텍스트 간
 * 미리 지정한 코드로 어떤 언어인지 출력함
 * @function
 * @param {String} 'ko', 'en', 'jp', 'cn', 'ep'
 * @returns {String} '한국어', 'English' , ...
 */
export const toLanguageText = (code) => {
  switch (code) {
    case TRANSLATE.CODE_ENG: return TRANSLATE.TEXT_ENG;
    case TRANSLATE.CODE_JP: return TRANSLATE.TEXT_JP;
    case TRANSLATE.CODE_CN: return TRANSLATE.TEXT_CN;
    case TRANSLATE.CODE_ESP: return TRANSLATE.TEXT_ESP;
    case TRANSLATE.CODE_PT: return TRANSLATE.TEXT_PT;
    case TRANSLATE.CODE_KOR:
    default: return TRANSLATE.TEXT_KOR;
  }
};

// function parse(str) {
//   var y = str.substr(0, 4);
//   var m = str.substr(4, 2);
//   var d = str.substr(6, 2);
//   return new Date(y,m-1,d);
// }
// var date = parse('20160418');

export const getFormatDate = (strDate = '') => {
  // 2021-06-01T00:00:00.000Z 데이터 형식
  if (strDate.length > 9) {
    const [dateString] = strDate.split('T');
    const splitedDateString = dateString.split('-');

    return splitedDateString.join('.');
  } else {
    //console.log('getFormatDate 값은 10자리 이상이어야 한다.:>> ', strDate);
    return '';
  }
};
