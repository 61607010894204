import Axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import React, {useEffect, useState} from 'react';

export default function FloatingBanner(props) {
  //console.log('notice popup banner--');
  //console.log(props);

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleClose = (e) => {
    e.preventDefault();
    setIsBannerVisible(false);
  };

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를

  const handleClick_custom = (a,b,c,d) => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '팝업공지',
        clickType: 'NC',
        value1: props.data[0].notice_title,
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };


  return (
      <>
        {isBannerVisible && (
            <div className="noticeBannerLayer_w current">
              <div className="noticeBannerLayer_inner">
                <div className="noticeBannerLayerTitle">
                  <Link href={props.data[0].link_url}>
                    <a onClick={(e) => handleClick_custom(e, '','0','all')} >
                      <span className="iconSvg_emergency"></span>
                      <p className="noticeTopBanner-text">
                        {props.data[0].notice_title}
                      </p>
                    </a>
                  </Link>
                </div>
                <div className="noticeBannerLayer_content">
                  <div className="noticeBannerLayer_cont">
                    {props.data[0].notice_content}
                  </div>
                </div>
                <button type="button" className="btn_noticeClosed" onClick={handleClose}>
                  <span className="iconSvg_closed"><i className="hide">닫기</i></span>
                </button>
              </div>
            </div>

        )}
      </>
  );
}
