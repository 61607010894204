import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default function allVod_Box(props) {

    const [swiperRef, setSwiperRef] = useState(null);
    let [maxPageCount, setMaxPageCount] = useState(0);
    let [naviCount, setNaviCount] = useState(1);

    let [maxPageCount1, setMaxPageCount1] = useState(0);
    let [naviCount1, setNaviCount1] = useState(1);

    let [maxPageCount2, setMaxPageCount2] = useState(0);
    let [naviCount2, setNaviCount2] = useState(1);

    useEffect(() => {
        if (swiperRef != null) {
            swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
        }

        setMaxPageCount(Math.ceil(props.data.contents1.length / 3));
        setMaxPageCount1(Math.ceil(props.data.contents2.length / 4));
        setMaxPageCount2(Math.ceil(props.data.contents3.length / 3));

    }, [])
    useEffect(() => {
        //alert('www');
        window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
    }, []);

    const handleClick_custom = (a,b,c,d) => {
        //alert('22222');
        window.__SBSLogModuleDataLayer.push({
            type: 'click',
            option: {
                clickName: 'AllVOD최상단타이틀',
                clickType: 'NC',
                value1: '',
                value2: '',
                value3: '',
                value4: '',
                value5: ''
            }
        });

    };

    const handleClick = (a,b,c,d,e,f,g,h) => {

        let moduleSubTitle;
        let contentId;
        let content_type;
        if (f == 'link') {
            content_type = 'LK';
        } else if (f == 'watch') {
            content_type = 'C';
        } else {
            content_type = 'LS';
        }
        if(d === '10338'){
            moduleSubTitle = '홍보타입';
            if (f == 'link') {
                contentId = 'link';
            } else if (f == 'watch') {
                contentId = h;
            } else {
                contentId = 'theme';
            }

        }else if(d === '11681'){
            contentId = b.uniq_no;
            moduleSubTitle = 'theme';
        }else{
            contentId = b.combi_id;
            d = contentId;
            e = b.title;
            moduleSubTitle = b.title;

        }


        //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
        window.__SBSLogModuleDataLayer.push({
            type: 'content',
            option: {
                moduleId: d, //모듈 아이디 없으면
                moduleTitle: 'ALLVOD',
                moduleSubTitle: moduleSubTitle,
                moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
                moduleSeq: props.loc_idx, //페이지에서 해당 모듈의 위치
                moduleItemSeq: c, //모듈 내에서 콘텐츠의 위치
                programId: g, //프로그램의 아이디 (추가)
                programTitle: '', //프로그램의 제목 (추가)
                contentId: contentId,//식별자
                contentTitle: b.title, //콘텐츠의 제목  (Require)
                contentNumber: '',
                contentType: content_type,
            }
        });
    };
    return (
        <>

            {/* <!-- 다크그레이 배경 --> */}
            <div className="mainContents-typeDarkgray">

                {/* <!-- allvod 타이틀 배너 --> */}
          <div className="contentsBlock_w allvodBanner_w">
            <a href="https://allvod.sbs.co.kr/free" onClick={(e) => handleClick_custom(e, '', '0', 'all')} target='_blank' className="allvodBnrLink">


              <strong className="iconSvg_allvod"><i className="hide">AllVOD</i></strong>
              <span className="allvodBnr-title">무료로 ZERO PLAY!</span>
              <span className="roundType-arrow"><i className="iconSvg_arrow"></i></span>
                    </a>
            <i className="allvodBg-topLeft"><img src="/images/ct_play.png" alt="" /></i>
            <i className="allvodBg-topRight"><img src="/images/ct_pause.png" alt="" /></i>
                </div>
                {/* <div className="contentsBlock_w allvodBanner_w">
                    <a href={props.data.alllink_url} className="allvodBnrLink">
                        <strong className="iconSvg_allvod"><i className="hide">AllVOD</i></strong>
                        <span className="allvodBnr-title">무료로 ZERO PLAY!</span>
                        <span className="allvodBnr-arrow"><i className="iconSvg_arrow"></i></span>
                    </a>
                    <i className="allvodBg-topLeft"><img src="/images/ct_play.png" alt="" /></i>
                    <i className="allvodBg-topRight"><img src="/images/ct_pause.png" alt="" /></i>
                </div> */}
                {/* <!-- 테마형 큐레이션 영역 --> */}
                <div className="contentsBlock_w">
                    <div className="moduleTitleline_w">
                        <h2 className="moduleTitleline-Title">{props.data.subtitle1}</h2>
                        <a href={props.data.contents1.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.contents1.alllink_yn == 'Y' && props.data.contents1.alllink_url != '' && props.data.contents1.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.contents1.alllink_title}</a>
                    </div>
                    <div className={"listModule_w_allvodCurationType1 allvodCurationType1" + props.loc_idx}>
                        <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next colorType-pink" onClick={() => setNaviCount(naviCount + 1)}>
                            <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                        </button>

                        <Swiper
                            onSwiper={setSwiperRef}
                            slidesPerView={3}
                            spaceBetween={24}
                            slidesPerGroup={3}
                            loop={false}
                            pagination={{ // 페이징 설정
                                el: `.allvodCurationType1${props.loc_idx} .swiper-pagination`,
                                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                            }}
                            navigation={
                                {
                                    nextEl: `.allvodCurationType1${props.loc_idx} .swiper-button-next`,
                                    prevEl: `.allvodCurationType1${props.loc_idx} .swiper-button-prev`,
                                }
                            }
                            modules={[Autoplay, Pagination, Navigation]}
                            className="listModuleSlider"
                            wrapperClass="SliderContent"
                        >
                            {
                                props.data.contents1.map((item, index) => {

                                    const handleOpenPopup = () => {
                                        //  485 * 928
                                        const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                                    }
                                    return (
                                        < SwiperSlide key = {index} >
                                            <div className="listModuleItem swiper-slide">
                                                <div className="moduleBoxWrap_clip">
                                                    <Link href={item.link_url} >
                                                        <a onClick={(e) => handleClick(e, item,index,'10338',props.data.subtitle1,item.type,item.pgm_id,item.mda_id)}  target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                                                            <div className="mb_image_w">
                                                                <div className="mb_image_inner">
                                                                    <img src={item.img_url} className="mb_image" alt={item.img_attr} />
                                                                </div>
                                                            </div>
                                                            <div className="mb_info_w">
                                                                <span className="mbif_title">{item.title}</span>
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                        <div className="swiper-pagination" style={{ visibility: (maxPageCount == 1) ? 'hidden' : 'visible' }}></div>
                        <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev colorType-pink" onClick={() => setNaviCount(naviCount - 1)}>
                            <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                        </button>
                    </div>
                </div>

                {/* <!-- 테마형 큐레이션 영역 --> */}
                <div className="contentsBlock_w">
                    <div className="moduleTitleline_w">
                        <h2 className="moduleTitleline-Title">{props.data.subtitle2}</h2>
                        <a href={props.data.contents2.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.contents1.alllink_yn == 'Y' && props.data.contents1.alllink_url != '' && props.data.contents1.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.contents1.alllink_title}</a>
                    </div>
                    <div className={"listModule_w_allvodCurationType2 allvodCurationType2" + props.loc_idx}>
                        <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next colorType-pink" onClick={() => setNaviCount1(naviCount1 + 1)}>
                            <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount1}</strong>/{maxPageCount1}</span>
                        </button>

                        <Swiper
                            onSwiper={setSwiperRef}
                            slidesPerView={4}
                            spaceBetween={24}
                            slidesPerGroup={4}
                            loop={false}
                            pagination={{ // 페이징 설정
                                el: `.allvodCurationType2${props.loc_idx} .swiper-pagination`,
                                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                            }}
                            navigation={
                                {
                                    nextEl: `.allvodCurationType2${props.loc_idx} .swiper-button-next`,
                                    prevEl: `.allvodCurationType2${props.loc_idx} .swiper-button-prev`,
                                }
                            }
                            modules={[Autoplay, Pagination, Navigation]}
                            className="listModuleSlider"
                            wrapperClass="SliderContent"
                        >
                            {
                                props.data.contents2.map((item, index) => {

                                    const handleOpenPopup = () => {
                                        //  485 * 928
                                        const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                                    }
                                    return (
                                        < SwiperSlide key = {index} >
                                            <div className="listModuleItem swiper-slide">
                                                <div className="moduleBoxWrap_theme">
                                                    <Link href={item.link_url} >
                                                        <a   onClick={(e) => handleClick(e, item,index,'11681',props.data.subtitle2,'','','')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                                                            <div className="mb_image_w">
                                                                <div className="mb_image_inner">
                                                                    <img src={item.img_url} className="mb_image" alt={item.attr} />
                                                                </div>
                                                            </div>
                                                            <div className="mb_info_w">
                                                                <span className="mbif_title">{item.title}</span>
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>

                        <div className="swiper-pagination" style={{ visibility: (maxPageCount1 == 1) ? 'hidden' : 'visible' }}></div>
                        <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev colorType-pink" onClick={() => setNaviCount1(naviCount1 - 1)}>
                            <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount1}</strong>/{maxPageCount1}</span>
                        </button>
                    </div>
                </div>


                {/* <!-- 테마형 큐레이션 영역 --> */}
                <div className="contentsBlock_w">
                    <div className="moduleTitleline_w">
                        <h2 className="moduleTitleline-Title">{props.data.subtitle3}</h2>
                        <a href={props.data.contents3.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.contents1.alllink_yn == 'Y' && props.data.contents1.alllink_url != '' && props.data.contents1.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.contents1.alllink_title}</a>
                    </div>
                    <div className={"listModule_w_allvodCurationType3 allvodCurationType3" + props.loc_idx}>
                        <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next colorType-pink" onClick={() => setNaviCount2(naviCount2 + 1)}>
                            <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount2}</strong>/{maxPageCount2}</span>
                        </button>

                        <Swiper
                            onSwiper={setSwiperRef}
                            slidesPerView={3}
                            spaceBetween={23}
                            slidesPerGroup={3}
                            loop={false}
                            pagination={{ // 페이징 설정
                                el: `.allvodCurationType3${props.loc_idx} .swiper-pagination`,
                                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                            }}
                            navigation={
                                {
                                    nextEl: `.allvodCurationType3${props.loc_idx} .swiper-button-next`,
                                    prevEl: `.allvodCurationType3${props.loc_idx} .swiper-button-prev`,
                                }
                            }
                            modules={[Autoplay, Pagination, Navigation]}
                            className="listModuleSlider"
                            wrapperClass="SliderContent"
                        >
                            {
                                props.data.contents3.map((item, index) => {

                                    const handleOpenPopup = () => {
                                        //  485 * 928
                                        const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                                    }
                                    return (
                                        < SwiperSlide key = {index} >

                                            <div className="listModuleItem swiper-slide">
                                                <div className="moduleBoxWrap_package">
                                                    <Link href={item.link_url} >
                                                        <a  onClick={(e) => handleClick(e, item,index,'paks','','','','')}  target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                                                            <div className="mb_image_w">
                                                                <div className="mb_image_inner">
                                                                    <img src={item.img_url} className="mb_image" alt={item.attr} />
                                                                </div>
                                                                <div className="label_package_w">
                                                                    <div className="package_number_w">
                                                                        <em className="package_number">{item.sale_per}</em>
                                                                        <span className="iconSvg_percent"><i className="hide">%</i></span>
                                                                    </div>
                                                                    <span className="iconSvg_packageText"><i className="hide">할인</i></span>
                                                                </div>
                                                            </div>
                                                            <div className="mb_info_w">
                                                                <span className="mbif_title">{item.title}</span>
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>

                        <div className="swiper-pagination" style={{ visibility: (maxPageCount2 == 1) ? 'hidden' : 'visible' }}></div>
                        <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev colorType-pink" onClick={() => setNaviCount2(naviCount2 - 1)}>
                            <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount2}</strong>/{maxPageCount2}</span>
                        </button>
                    </div>
                </div>



            </div>
        </>
    );

}