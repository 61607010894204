import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

export default function free4Box(props) {

  // 모듈러 링크 리액트 hook
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c,d) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    let contentId;
    let contentTitle;
    let conver_current_s = (parseInt(d) + 1);
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '자유형4', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 제목 (추가)
        contentId: '자유형4',//식별자
        contentTitle: props.data.title, //콘텐츠의 제목  (Require)
        contentType: 'LK', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };




  return (
    <div className="swiper-slide slide-slide">
      <div className="swiper-cont">
        <div className="spotModuleGroup3">
          {
            props.data.contents.map((item, index) => {
              //console.log('free4box-----')
              return (
                <div key={'mtf4_' + index} className={`spotModuleWrap_freeType2 spotModuleWrap_ContentPhoto ${item.title == '' || item.title == null ? 'type_noText' : ''}`}>
                  {
                    item.link_url == null || item.link_url == ''
                      ? (
                        <>
                          <div className="spotModuleLink">
                            <span className="spotModuleImage_w">
                              <Image
                                src={item.pc_img_url}
                                className="spotModuleImg"
                                layout='fixed'
                                width={282}
                                height={450}
                                quality={item.imageQuality || 75}
                                alt={item.pc_img_attr}
                              />
                            </span>
                            <div className="spotProgramCont">
                              <strong className="spotProgram-Title">{item.title}</strong>
                            </div>
                          </div>

                        </>
                      )
                      :
                      (
                        <>
                          <a href={item.link_url} >
                            <a onClick={(e) => handleClick(e, item, '4free',index)} target={item.link_new_yn === 'Y' ? '_blank' : ''} className="spotModuleLink">
                              <span className="spotModuleImage_w">
                                <Image
                                  src={item.pc_img_url}
                                  className="spotModuleImg"
                                  layout='fixed'
                                  width={282}
                                  height={450}
                                  quality={item.imageQuality || 75}
                                  alt={item.pc_img_attr}
                                />
                              </span>
                              <div className="spotProgramCont">
                                <strong className="spotProgram-Title">{item.title}</strong>
                              </div>
                            </a>
                          </a>
                        </>
                      )
                  }
                </div>
              );
            })
          }

        </div>
      </div>
    </div>

  );
}
