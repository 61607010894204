import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import PGMBoxBTNArea from "./PGMBoxBTNArea";

export default function AdBox(props) {
    const areaCheck = props.data.type;

    useEffect(() => {
        //alert('wwww== ' + areaCheck);
    }, []); // 빈 dependency 배열을 사용하여 컴포넌트가 마운트 될 때만 alert가 호출되도록 합니다.

    let srcUrl;

    if (areaCheck === 'AD1') {
        srcUrl = '/html/pc/top.htm';
    } else if (areaCheck === 'AD2') {
        srcUrl = '/html/pc/middle.htm';
        // } else if (areaCheck === 'live') {
        //     srcUrl = '/html/pc/live.htm';
    } else {
        srcUrl = '/html/pc/bottom.htm';
    }

    return (
        <div className="contentsBlock_w">
            <div className="adBanner_w">
                <iframe title='배너광고' display='block' src={srcUrl} width='970px' height='90px' scrolling='no' frameBorder='0' align='middle' marginWidth='0' marginHeight='0' />
            </div>
        </div>
    );
}