import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from "react";
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';


export default function eventBox(props) {
  // 모듈러 링크 리액트 hook
  useEffect(() => {
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c) => {
    let conver_current_s = (parseInt(props.currentSlide) + 1);
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '이벤트형', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s,
        programId: '', //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 제목 (추가)
        contentId: '이벤트',//식별자
        contentTitle: b.pc_img_attr, //콘텐츠의 제목  (Require)
        contentType: 'LK', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };


  return (
    <div className="swiper-slide slide-slide">
      <div className="swiper-cont">
        {/* <!-- 이벤트형 --> */}
        {
          props.data.contents.map((item, index) => {
            if (item.type == 'EVENT') {
              return (
                <div key={'mte_' + index} className="spotModuleWrap_event">
                  {
                    item.link_url == null || item.link_url == ''
                      ? (
                        <>
                          <Image src={item.pc_img_url} className="spotModuleImg" layout='fill' quality={item.imageQuality || 100} alt={item.pc_img_attr} />
                        </>
                      )
                      :
                      (
                        <>
                          <a href={item.link_url} >
                            <a onClick={(e) => handleClick(e, item, 'event')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleImage_w">
                              <Image src={item.pc_img_url} className="spotModuleImg" layout='fill' quality={item.imageQuality || 100} alt={item.pc_img_attr} />
                            </a>
                          </a>
                        </>
                      )
                  }
                </div>
              );
            }
          })

        }

      </div>
    </div >
  );
}
